.toggle-sidebar-button {
  background-color: unset;
  border: none;
  padding: 0 20px;
}

.dropdown-menu.dropdown-menu--overwrite {
  left: auto !important;
  top: 130%;
}
