.label-required,
.required-hint {
  position: relative;

  &::after {
    content: '*';
    color: #ff0080;
    position: absolute;
    right: -12px;
  }
}

.required-hint {
  display: inline-block;
  font-style: italic;
  line-height: 30px;
  margin-bottom: 20px;
  font-style: italic;
}
